import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./noticeBoard.scss";
import TitleBox from "../../components/TitleBox";

const NoticeBoard = () => {
  const [noticeNotices, setNoticeNotices] = useState([]);
  const [generalNotices, setGeneralNotices] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    searchQuery: "",
    searchTypes: { title: true, author: true, content: true },
    startDate: "",
    endDate: "",
  });
  const navigate = useNavigate(); // navigate 함수 사용

  useEffect(() => {
    // 현재 연도와 월을 구해서 날짜를 설정
    const today = new Date();
    const currentYear = today.getFullYear();
    const firstMonth = "01"; // January
    const lastMonth = "12"; // December

    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: `${currentYear}-${firstMonth}`,
      endDate: `${currentYear}-${lastMonth}`,
    }));
  }, []);

  const fetchNotices = async () => {
    try {
      const { searchQuery, searchTypes, startDate, endDate } = filters;

      // searchTypes를 배열로 변환
      const searchTypeArray = Object.keys(searchTypes).filter(
        (type) => searchTypes[type]
      );
      console.log(searchTypeArray);
      const response = await axios.get("/api/tm/notices", {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          searchQuery, // 검색어
          searchTypes: searchTypeArray, // 검색 타입 배열
          startDate, // 시작 날짜
          endDate, // 종료 날짜
        },
      });
      console.log(response);

      const { noticeNotices, generalNotices, totalPages } = response.data;
      setNoticeNotices(noticeNotices);
      setGeneralNotices(generalNotices);
      setTotalPages(totalPages);
    } catch (error) {
      console.error(
        "Error fetching notices:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    fetchNotices();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleReset = () => {
    setFilters({
      searchQuery: "",
      searchTypes: { title: true, author: true, content: true },
      startDate: `${new Date().getFullYear()}-01`,
      endDate: `${new Date().getFullYear()}-12`,
    });
    console.log(filters.startDate);
    setCurrentPage(1); // 초기화 시 첫 페이지로 이동
  };

  const handleFilterChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "searchQuery") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchQuery: value,
      }));
    } else if (name in filters.searchTypes) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchTypes: {
          ...prevFilters.searchTypes,
          [name]: checked,
        },
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const handleSearch = (event) => {
    fetchNotices();
    event.preventDefault();
    // 검색 버튼 클릭 시에만 데이터 가져오기
    setCurrentPage(1); // 검색 시 첫 페이지로 이동
  };

  const handleRowClick = (id) => {
    navigate(`/tm/notices/${id}`); // 게시글 ID를 포함한 경로로 이동
  };

  const handleNewClick = (id) => {
    navigate(`/tm/notices/new`); // 게시글 ID를 포함한 경로로 이동
  };

  return (
    <div className="notice_board_container container">
      <TitleBox mainmenu="TM" submenu="공지" />
      <div className="notice_head">
        <form onSubmit={handleSearch}>
          <div className="search_box">
            <div className="search_type_box">
              <div className="search_types">
                <label>
                  <input
                    type="checkbox"
                    name="title"
                    checked={filters.searchTypes.title}
                    onChange={handleFilterChange}
                  />
                  제목
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="author"
                    checked={filters.searchTypes.author}
                    onChange={handleFilterChange}
                  />
                  작성자
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="content"
                    checked={filters.searchTypes.content}
                    onChange={handleFilterChange}
                  />
                  내용
                </label>
              </div>
            </div>
            <input
              type="text"
              name="searchQuery"
              className="searchText"
              value={filters.searchQuery}
              onChange={handleFilterChange}
              placeholder="검색할 내용을 입력하세요."
            />
            <button className="submitBtn" type="submit">
              검색
            </button>
          </div>

          <div className="search_date">
            날짜{` `}
            <input
              type="month"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
            />
            {` `}~{` `}
            <input
              type="month"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
            />
          </div>

          <div className="etcBtnBox">
            <div className="etcBtn">
              <span></span>
            </div>
            <div className="btns">
              <div type="button" onClick={handleReset}>
                검색 초기화
              </div>
            </div>
          </div>
        </form>

        <button className="newBtn" onClick={handleNewClick}>
          + 새글 작성
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>수정일</th>
          </tr>
        </thead>
        <tbody>
          {noticeNotices.length > 0 && (
            <>
              {noticeNotices.map((notice) => (
                <tr
                  key={notice.id}
                  className="notice_tr"
                  onClick={() => handleRowClick(notice.id)}
                >
                  <td>
                    <img src="/images/notice/notice_icon.png" alt="공지사항" />
                  </td>
                  <td className="notice_title">{notice.title}</td>
                  <td>{notice.user.username}</td>
                  <td>
                    {new Date(notice.created_at).toISOString().split("T")[0]}
                  </td>
                  <td>
                    {new Date(notice.updated_at).toISOString().split("T")[0]}
                  </td>
                </tr>
              ))}
            </>
          )}
          {generalNotices.length > 0 ? (
            generalNotices.map((notice) => (
              <tr
                key={notice.id}
                className="general_tr"
                onClick={() => handleRowClick(notice.id)}
              >
                <td>{notice.id}</td>
                <td className="notice_title">{notice.title}</td>
                <td>{notice.user.username}</td>
                <td>
                  {new Date(notice.created_at).toISOString().split("T")[0]}
                </td>
                <td>
                  {new Date(notice.updated_at).toISOString().split("T")[0]}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No general posts available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
};

export default NoticeBoard;
