import React, { useState, useEffect } from "react";
import axios from "axios";

const Wonho = () => {
  const [finances, setFinances] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(
    ("0" + (new Date().getMonth() + 1)).slice(-2)
  );

  useEffect(() => {
    const fetchFinances = async () => {
      try {
        const response = await axios.get(`/api/adexpense/hospital-finances`, {
          params: {
            year: year,
            month: month,
          },
        });
        console.log("Fetched data:", response.data); // 데이터 구조 확인
        setFinances(response.data);
      } catch (error) {
        console.error("Error fetching finances:", error);
      }
    };

    fetchFinances();
  }, [year, month]);

  return (
    <div>
      <h1>Hospital Finance Data</h1>
      <div>
        <label>Year: </label>
        <input
          type="text"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        <label>Month: </label>
        <input
          type="text"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Hospital Name</th>
            <th>Target Revenue</th>
            <th>Total Ad Spend</th>
          </tr>
        </thead>
        <tbody>
          {finances.length > 0 ? (
            finances.map((finance, index) => (
              <tr key={index}>
                <td>{finance.hospital_name?.name || "N/A"}</td>{" "}
                {/* hospital_name.name을 안전하게 참조 */}
                <td>${finance.targetRevenue || 0}</td>{" "}
                {/* targetRevenue 기본값 */}
                <td>${finance.ad_spend || 0}</td> {/* ad_spend 기본값 */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No data available for the selected month</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Wonho;
