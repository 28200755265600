import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const YearFilter = ({ selectedYear, setSelectedYear }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (year) => {
    setSelectedYear(year);
    setIsDropdownOpen(false);
  };

  // 현재 연도부터 5년 전까지의 년도 생성
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 6 }, (_, index) => currentYear - index);

  return (
    <div className="year-filter">
      <div className="hospital-custom-select" onClick={handleDropdownClick}>
        {selectedYear}
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
      </div>

      {isDropdownOpen && (
        <ul className="custom-options">
          {years.map((year) => (
            <li
              key={year}
              onClick={() => handleOptionClick(year.toString())}
              className={`option-item ${
                selectedYear === year.toString() ? "selected" : ""
              }`}
            >
              {year}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default YearFilter;
