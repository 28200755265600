import { useState, useEffect } from "react";
import axios from "axios";
import TitleBox from "../../../components/TitleBox";

import "./blacklist.scss";
import CustomDropdown from "../listsetting/CustomDropdown";
import DeleteBox from "../listsetting/DeleteBox";
import AlertModal from "../../../components/AlertModal";

const Blacklist = () => {
  const [blacklist, setBlacklist] = useState([]);
  const [newBlack, setNewBlack] = useState({
    name: "",
    phone: "",
    ip: "",
  });
  const [selectedBlack, setSelectedBlack] = useState(null); // 선택된 행 상태
  const itemsPerPage = 10; // 한 페이지에 표시할 항목 수
  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수

  const [showModal, setShowModal] = useState(false); // 모달 표시 여부

  // 블랙리스트 데이터 가져오기
  const fetchBlacklist = async () => {
    console.log("1");
    try {
      const response = await axios.get(
        `/api/admin/black/list?page=${currentPage}&limit=${itemsPerPage}`
      );
      setBlacklist(response.data.blacklist);
      setTotalPages(response.data.totalPages); // 서버에서 받은 전체 페이지 수
      setCurrentPage(response.data.currentPage); // 서버에서 받은 현재 페이지
    } catch (error) {
      console.error("데이터 로딩 오류:", error);
      alert("데이터를 불러오는데 실패했습니다.");
      setBlacklist([]);
    }
  };
  useEffect(() => {
    fetchBlacklist();
  }, [currentPage]);

  // ----------------------------------- 페이지 네이션
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // 페이지 변경
    }
  };

  // 입력 필드 변경 핸들러 (새 블랙)
  const handleNewBlackChange = (name, value) => {
    setNewBlack({
      ...newBlack,
      [name]: value,
    });
  };

  // 블랙 등록 핸들러 (POST)
  const handleRegister = async (field) => {
    const data = { [field]: newBlack[field] };

    try {
      await axios.post("/api/admin/black/new", data);

      // 블랙 데이터 갱신
      fetchBlacklist();
      // newBlack 초기화
      setNewBlack((prevState) => ({
        ...prevState,
        [field]: "", // 등록된 속성만 초기화
      }));
    } catch (error) {
      console.error("블랙 등록 중 오류가 발생했습니다:", error);
      alert("블랙 등록에 실패했습니다.");
    }
  };

  // 체크박스 변경 핸들러 (단일 선택)
  const handleCheckboxChange = (id) => {
    setSelectedBlack((prevSelectedBlack) =>
      prevSelectedBlack === id ? null : id
    );
  };

  // 삭제 버튼 클릭 시 모달을 띄움
  const handleDelete = () => {
    if (selectedBlack === null) {
      alert("삭제할 블랙을 선택하세요.");
      return;
    }

    setShowModal(true); // 모달 표시
  };

  const handleConfirmDelete = async () => {
    try {
      // 선택된 블랙리스트 항목 삭제 요청
      console.log(selectedBlack);
      await axios.delete(`/api/admin/black/${selectedBlack}`);

      // 삭제 후 블랙리스트 데이터 갱신
      fetchBlacklist();
      setSelectedBlack(null); // 삭제 후 선택 상태 초기화
      alert("블랙 리스트 항목이 삭제되었습니다.");
    } catch (error) {
      console.error("블랙 삭제 중 오류가 발생했습니다:", error);
      alert("블랙 삭제에 실패했습니다.");
    } finally {
      setShowModal(false); // 모달 닫기
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false); // 모달 닫기
  };

  return (
    <div className="black_container container_left">
      <TitleBox mainmenu="관리자페이지" submenu="블랙 리스트" />

      {/* 블랙 등록 섹션 */}
      <div className="black-form">
        <div className="form-group">
          <label>블랙 이름</label>
          <input
            type="text"
            name="name"
            value={newBlack.name}
            onChange={(e) => handleNewBlackChange("name", e.target.value)}
            placeholder="블랙 리스트에 추가될 이름/단어를 입력하세요"
          />
          <button onClick={() => handleRegister("name")}>등록</button>
        </div>
        <div className="form-group">
          <label>블랙 번호</label>
          <input
            type="number"
            name="phone"
            value={newBlack.phone}
            onChange={(e) => handleNewBlackChange("phone", e.target.value)}
            placeholder="블랙 리스트에 추가될 번호를 입력하세요"
          />
          <button onClick={() => handleRegister("phone")}>등록</button>
        </div>
        <div className="form-group">
          <label>블랙 ip</label>
          <input
            type="text"
            name="ip"
            value={newBlack.ip}
            onChange={(e) => handleNewBlackChange("ip", e.target.value)}
            placeholder="블랙 리스트에 추가될 ip를 입력하세요"
          />
          <button onClick={() => handleRegister("ip")}>등록</button>
        </div>
      </div>

      {/* 블랙 테이블 */}
      <h2 className="table_title">블랙 리스트</h2>

      <table className="black-table">
        <thead>
          <tr>
            <th style={{ width: "8%" }}>선택</th>
            <th style={{ width: "28%" }}>블랙 이름</th>
            <th style={{ width: "28%" }}>블랙 번호</th>
            <th style={{ width: "28%" }}>블랙 ip</th>
            <th style={{ width: "8%" }}>상태</th>
          </tr>
        </thead>
        <tbody>
          {blacklist &&
            blacklist.map((black) => (
              <tr
                key={black.id}
                className={selectedBlack === black.id ? "selected" : ""}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectedBlack === black.id}
                    onChange={() => handleCheckboxChange(black.id)}
                  />
                </td>
                <td>{black.name}</td>
                <td>{black.phone}</td>
                <td>{black.ip}</td>
                <td>
                  <button onClick={handleDelete}>삭제</button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* AlertModal 컴포넌트 */}
      {showModal && (
        <AlertModal
          message="정말로 삭제하시겠습니까?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
};

export default Blacklist;
