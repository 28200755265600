import React, { useEffect, useState } from "react";
import ExpenseHistory from "./ExpenseHistory";
import ExpenseInput from "./ExpenseInput";
import TitleBox from "../../components/TitleBox";
import CustomDropdown from "../admin/listsetting/CustomDropdown";
import axios from "axios";
import "./advertisingExpenses.scss";
import AdExcelUpload from "../adcomponent/AdExcelUpload";

function AdvertisingExpenses() {
  // "input" 상태는 ExpenseInput을 보여주고, "history" 상태는 ExpenseHistory를 보여줍니다.
  const [view, setView] = useState(() => {
    return localStorage.getItem("view") || "input";
  });
  const [searchStatus, setSearchStatus] = useState(false);

  const [formData, setFormData] = useState({
    search_hospital_name_id: "",
    search_title: "",
  });

  const [hospitals, setHospitals] = useState([]); // 병원 목록 상태

  // 상태가 변경될 때마다 localStorage에 저장
  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);

  const searchHandler = () => {
    setSearchStatus(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hospitalRes = await axios.get("/api/list/hospitals"); // 병원 데이터만 가져오는 API 호출

        // API로 받은 데이터를 드롭다운의 options 형식으로 변환
        // const hospitalOptions = hospitalRes.data.items.map((hospital) => ({
        //   value: hospital.id,
        //   label: hospital.name,
        // }));
        const hospitalOptions = [
          { value: "", label: "병원 전체 목록" }, // 빈 문자열 항목 추가
          ...hospitalRes.data.items.map((hospital) => ({
            value: hospital.id,
            label: hospital.name,
          })),
        ];
        // 상태 업데이트
        setHospitals(hospitalOptions);
      } catch (error) {
        console.error("병원 데이터를 불러오는 중 오류가 발생했습니다:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="advertisingExpenses container_flex">
      <div className="container_left">
        <TitleBox
          mainmenu="광고비관리"
          submenu={view === "input" ? "광고비 입력" : "광고비 히스토리 "}
        />
        {view === "input" && (
          <ExpenseInput
            formData={formData}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
          />
        )}
        {view === "history" && (
          <ExpenseHistory
            formData={formData}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
          />
        )}
      </div>
      <div className="container_right">
        <div className="search-group">
          <div className="search_hospital">
            <CustomDropdown
              options={hospitals}
              selectedValue={formData.search_hospital_name_id || ""}
              onChange={(value) =>
                handleDropdownChange("search_hospital_name_id", value)
              }
              bigDrop={1}
              search={1}
            />
          </div>
          <div className="search_title_box">
            <input
              type="text"
              name="search_title"
              className="search_title"
              value={formData.search_title || ""}
              onChange={handleChange}
              placeholder="광고 제목을 검색하세요"
            />
            <button
              type="button"
              className="search_btn"
              onClick={searchHandler}
            >
              검색
            </button>
          </div>
          <AdExcelUpload />
          <div className="changeBtns">
            <button
              className={`${view === "input" ? "activeBtn" : "inactive"}`}
              onClick={() => setView("input")}
            >
              광고비 입력
            </button>
            <button
              className={`${view === "history" ? "activeBtn" : "inactive"}`}
              onClick={() => setView("history")}
            >
              광고비 히스토리
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvertisingExpenses;
