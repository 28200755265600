import React, { useState } from "react";
import axios from "axios";

function UrlCodeMetrics() {
  const getKoreaDate = () => {
    const koreaTime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Seoul",
    });
    return new Date(koreaTime).toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(getKoreaDate());
  const [endDate, setEndDate] = useState("");
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [totals, setTotals] = useState({ dbClickTotal: 0, dbRequestTotal: 0 }); // 합계를 저장하는 상태

  const correctPassword = "0703";

  const calculateTotals = (data) => {
    const dbClickTotal = data.reduce(
      (sum, metric) => sum + metric.db_click_count_sum,
      0
    );
    const dbRequestTotal = data.reduce(
      (sum, metric) => sum + metric.db_request_count_sum,
      0
    );
    setTotals({ dbClickTotal, dbRequestTotal });
  };

  const fetchMetrics = async () => {
    setLoading(true);
    setError(null);
    setMetrics([]);

    try {
      const response = await axios.post("/api/admin/metrics", {
        startDate,
        endDate,
      });

      if (response.data.data.length === 0) {
        setError("No data found for the specified date range.");
      } else {
        setMetrics(response.data.data);
        calculateTotals(response.data.data); // 데이터를 가져온 후 합계 계산
      }
    } catch (err) {
      setError("Failed to fetch metrics.");
      console.error("Error fetching metrics:", err);
    }

    setLoading(false);
  };

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      setError("Incorrect password.");
    }
  };

  return (
    <div className="UrlCodeMetrics-container">
      {!authenticated ? (
        <div className="UrlCodeMetrics-auth">
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="UrlCodeMetrics-passwordInput"
          />
          <button
            onClick={handlePasswordSubmit}
            className="UrlCodeMetrics-authButton"
          >
            Authenticate
          </button>
          {error && <p className="UrlCodeMetrics-errorMessage">{error}</p>}
        </div>
      ) : (
        <>
          <h2 className="UrlCodeMetrics-title">URL Code Metrics</h2>
          <div className="UrlCodeMetrics-filterForm">
            <label className="UrlCodeMetrics-label">
              조회 시작 날짜:
              <input
                type="date"
                className="UrlCodeMetrics-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min="2024-11-14"
              />
            </label>
            <label className="UrlCodeMetrics-label">
              조회 끝 날짜:
              <input
                type="date"
                className="UrlCodeMetrics-input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
            <button className="UrlCodeMetrics-button" onClick={fetchMetrics}>
              Fetch Metrics
            </button>
          </div>

          {loading && <p className="UrlCodeMetrics-loading">Loading...</p>}
          {error && <p className="UrlCodeMetrics-errorMessage">{error}</p>}

          {/* 합계 표시 */}
          <div className="UrlCodeMetrics-totals">
            <p>누적 디비 접속 횟수: {totals.dbClickTotal}</p>
            <p>누적 디비 갯수(삭제 데이터 포함): {totals.dbRequestTotal}</p>
          </div>

          <div className="UrlCodeMetrics-table">
            {metrics.length > 0 ? (
              <table className="UrlCodeMetrics-tableContent">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>랜딩 제목</th>
                    <th>신청 완료 접속 횟수</th>
                    <th>신청하기 클릭 횟수</th>
                    <th>디비 갯수</th>
                    <th>랜딩 접속 횟수</th>
                  </tr>
                </thead>
                <tbody>
                  {metrics.map((metric) => (
                    <tr key={metric.url_code_setting_id}>
                      <td>{metric.url_code_setting_id}</td>
                      <td>{metric.ad_title}</td>
                      <td>{metric.endpage_mount_count_sum}</td>
                      <td
                        style={{
                          color:
                            metric.db_request_count_sum !==
                            metric.customer_count
                              ? "red"
                              : "none",
                        }}
                      >
                        {metric.db_request_count_sum}
                      </td>
                      <td
                        style={{
                          color:
                            metric.db_request_count_sum !==
                            metric.customer_count
                              ? "red"
                              : "none",
                        }}
                      >
                        {metric.customer_count}
                      </td>
                      <td>{metric.db_click_count_sum}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              !loading && (
                <p className="UrlCodeMetrics-noMetrics">No metrics found.</p>
              )
            )}
          </div>
        </>
      )}
      <style jsx>{`
        .UrlCodeMetrics-container {
          padding: 40px;
          max-width: 1200px;
          height: 700px;
          overflow: auto;
          margin: 0 auto;
          background-color: #f8fafc;
          border-radius: 12px;
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
          font-family: "Helvetica Neue", sans-serif;
          color: #333;
        }

        /* 인증 화면 스타일 */
        .UrlCodeMetrics-auth {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
        }

        .UrlCodeMetrics-passwordInput {
          margin-bottom: 15px;
          padding: 12px;
          font-size: 15px;
          width: 250px;
          border: 1px solid #d1d5db;
          border-radius: 8px;
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: border-color 0.3s ease;
        }

        .UrlCodeMetrics-passwordInput:focus {
          outline: none;
          border-color: #3b82f6;
        }

        .UrlCodeMetrics-authButton {
          padding: 12px 25px;
          font-size: 15px;
          background: linear-gradient(135deg, #3b82f6, #2563eb);
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 8px;
          transition: background 0.3s ease, transform 0.2s ease;
        }

        .UrlCodeMetrics-authButton:hover {
          background: linear-gradient(135deg, #2563eb, #1e3a8a);
          transform: scale(1.05);
        }

        .UrlCodeMetrics-errorMessage {
          color: #ef4444;
          font-size: 14px;
          margin-top: 10px;
        }

        /* 필터 폼 스타일 */
        .UrlCodeMetrics-filterForm {
          display: flex;
          gap: 20px;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .UrlCodeMetrics-label {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          color: #374151;
        }

        .UrlCodeMetrics-input {
          padding: 10px;
          font-size: 15px;
          border-radius: 8px;
          border: 1px solid #d1d5db;
          margin-top: 5px;
          transition: border-color 0.3s ease;
        }

        .UrlCodeMetrics-input:focus {
          border-color: #3b82f6;
          outline: none;
        }

        .UrlCodeMetrics-button {
          padding: 10px 20px;
          background: linear-gradient(135deg, #10b981, #047857);
          color: #ffffff;
          font-weight: 600;
          border: none;
          cursor: pointer;
          border-radius: 8px;
          transition: background 0.3s ease, transform 0.2s ease;
        }

        .UrlCodeMetrics-button:hover {
          background: linear-gradient(135deg, #047857, #065f46);
          transform: scale(1.05);
        }

        /* 로딩 메시지 */
        .UrlCodeMetrics-loading {
          color: #3b82f6;
          font-weight: 500;
          font-size: 15px;
        }

        /* 테이블 스타일 */
        .UrlCodeMetrics-tableContent {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .UrlCodeMetrics-tableContent th,
        .UrlCodeMetrics-tableContent td {
          padding: 12px 15px;
          text-align: center;
        }

        .UrlCodeMetrics-tableContent th {
          background-color: #3b82f6;
          color: #ffffff;
          font-weight: 600;
          border-top: 2px solid #3b82f6;
        }

        .UrlCodeMetrics-tableContent td {
          background-color: #ffffff;
          border-top: 1px solid #e5e7eb;
        }

        .UrlCodeMetrics-tableContent tr:nth-child(even) td {
          background-color: #f9fafb;
        }

        .UrlCodeMetrics-noMetrics {
          color: #6b7280;
          font-size: 16px;
          text-align: center;
          margin-top: 20px;
        }
        .UrlCodeMetrics-totals {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 600;
          color: #374151;
        }
      `}</style>
    </div>
  );
}

export default UrlCodeMetrics;
