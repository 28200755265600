import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBox from "../../components/TitleBox";
import "./revenueAndCostSetting.scss";
import HospitalFilter from "./HospitalFilter";
import YearFilter from "./YearFilter";
import MonthFilter from "./MonthFilter";
import AlertBox from "../admin/listsetting/AlertBox";
import DeleteBox from "../admin/listsetting/DeleteBox";

function RevenueAndCostSetting() {
  const [data, setData] = useState([]); // 데이터 상태
  const [editState, setEditState] = useState({}); // 각 행/필드의 수정 상태를 관리
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const itemsPerPage = 10;

  const [showAlert, setShowAlert] = useState(false); // AlertBox 표시 상태

  const [showDeleteBox, setShowDeleteBox] = useState(false); // DeleteBox 표시 상태
  const [deleteTarget, setDeleteTarget] = useState({}); // 삭제 대상 (id와 fieldKey 저장)

  const fields = [
    { key: "targetRevenue", label: "목표 매출" },
    { key: "depositAmount", label: "병원 입금액" },
    { key: "targetActual", label: "목표 실비" },
    { key: "dbAdBudget", label: "DB 광고 예산" },
    { key: "displayAdBudget", label: "노출 광고 예산" },
    { key: "directorCardSpending", label: "원장님 카드 지출" },
  ];

  // 한국 시간 설정
  useEffect(() => {
    const now = new Date();
    const koreaTimeOffset = 9 * 60 * 60 * 1000; // UTC+9
    const kstDate = new Date(now.getTime() + koreaTimeOffset);

    setSelectedYear(kstDate.getFullYear().toString());
    setSelectedMonth((kstDate.getMonth() + 1).toString().padStart(2, "0"));
  }, []);

  const fetchData = async () => {
    if (!selectedHospital) {
      setShowAlert(true); // AlertBox 표시
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get("/api/revenue/list", {
        params: {
          page: currentPage,
          itemsPerPage,
          year: selectedYear,
          month: selectedMonth,
          hospital_name_id: selectedHospital,
        },
      });

      let responseData = response.data.data || response.data;

      // 데이터가 없을 경우 기본값으로 항목 생성 (하지만 서버에는 POST하지 않음)
      const filledData =
        responseData.length > 0
          ? responseData
          : [
              {
                id: "temp", // 임시 ID
                hospital_name_id: selectedHospital,
                year: selectedYear,
                month: selectedMonth,
                ...fields.reduce((acc, field) => {
                  acc[field.key] = 0; // 기본값 0 설정
                  return acc;
                }, {}),
              },
            ];

      setData(filledData);

      setEditState(
        filledData.reduce((acc, item) => {
          acc[item.id] = fields.reduce((fieldAcc, field) => {
            fieldAcc[field.key] = false; // 각 필드의 초기 수정 상태
            return fieldAcc;
          }, {});
          return acc;
        }, {})
      );

      setTotalPages(response.data.totalPages || 1);
    } catch (err) {
      setError("데이터를 가져오는 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleEditToggle = async (id, fieldKey) => {
    const isEditing = editState[id]?.[fieldKey] || false;

    if (isEditing) {
      try {
        const updatedItem = data.find((item) => item.id === id);

        // id가 "temp"인 경우 POST 요청 실행
        if (id === "temp") {
          const postResponse = await axios.post(
            "/api/revenue/list",
            updatedItem
          );

          // POST 요청 성공 시 새 ID로 데이터 업데이트
          setData((prevData) =>
            prevData.map((item) =>
              item.id === "temp" ? { ...item, id: postResponse.data.id } : item
            )
          );

          alert("새 데이터를 성공적으로 생성하였습니다.");
        } else {
          // 기존 데이터에 대해서는 PUT 요청 실행
          await axios.put(`/api/revenue/list/${id}`, {
            [fieldKey]: updatedItem[fieldKey], // 수정된 필드만 서버에 전송
          });
          alert(`${fieldKey} 수정이 완료되었습니다.`);
        }
      } catch (error) {
        alert(`${fieldKey} 저장 중 오류가 발생했습니다.`);
      }
    }

    setEditState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [fieldKey]: !isEditing, // 필드별 수정 상태 토글
      },
    }));
  };

  const handleDelete = (id, fieldKey, fieldLabel) => {
    setDeleteTarget({
      id,
      fieldKey,
      message: `${fieldLabel} 값을 0으로 설정하시겠습니까?`,
    }); // 삭제 대상 설정
    setShowDeleteBox(true); // DeleteBox 표시
  };

  const handleCancelDelete = () => {
    setShowDeleteBox(false); // DeleteBox 숨기기
  };

  const handleConfirmDelete = async () => {
    const { id, fieldKey } = deleteTarget;

    try {
      const updatedData = data.map((item) =>
        item.id === id
          ? {
              ...item,
              [fieldKey]: 0,
            }
          : item
      );

      await axios.put(`/api/revenue/list/${id}`, {
        [fieldKey]: 0,
      });

      setData(updatedData);
      alert(`${fieldKey} 값이 0으로 설정되었습니다.`);
    } catch (err) {
      alert(`${fieldKey} 값 업데이트 중 오류가 발생했습니다.`);
    } finally {
      setShowDeleteBox(false); // DeleteBox 숨기기
    }
  };

  return (
    <div className="listContainer container_flex">
      <div className="TabSetting container_left">
        <TitleBox mainmenu="관리자페이지" submenu={"매출 및 실비 설정"} />
        <div className="hosptal_filter_temp">
          <div className="filter_flex_row">
            <HospitalFilter
              selectedHospital={selectedHospital}
              setSelectedHospital={setSelectedHospital}
            />
            <YearFilter
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
            <MonthFilter
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </div>
          <div>
            <button onClick={fetchData} className="searchBTN">
              검색
            </button>
          </div>
        </div>

        <table className="data-table">
          <thead>
            <tr>
              <th style={{ width: "11%", paddingLeft: "10px" }}>정산</th>
              <th style={{ width: "14%" }}>금액</th>
              <th style={{ width: "8%", textAlign: "center" }}>금액입력상태</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) =>
              fields.map((field) => (
                <tr key={`${field.key}-${item.id}`}>
                  <td style={{ paddingLeft: "10px" }}>{field.label}</td>
                  <td>
                    <input
                      type="text"
                      value={item[field.key]?.toLocaleString() || ""}
                      className={`form-control ${
                        editState[item.id]?.[field.key] ? "RevEditedMode" : ""
                      }`}
                      readOnly={!editState[item.id]?.[field.key]}
                      onChange={(e) => {
                        const updatedData = data.map((row) =>
                          row.id === item.id
                            ? { ...row, [field.key]: e.target.value }
                            : row
                        );
                        setData(updatedData);
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <button
                      className="listSaveBTN"
                      onClick={() => handleEditToggle(item.id, field.key)}
                    >
                      {editState[item.id]?.[field.key] ? "저장" : "수정"}
                    </button>
                    <button
                      style={{
                        marginLeft: "10px",
                        marginRight: "40px",
                      }}
                      className="RevlistDeleteBTN"
                      onClick={() =>
                        handleDelete(item.id, field.key, field.label)
                      }
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <div className="pagination">
          <button onClick={() => handlePageChange(1)}>{"<<"}</button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={page === currentPage ? "currentPage" : ""}
              >
                {page}
              </button>
            )
          )}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
          <button onClick={() => handlePageChange(totalPages)}>{">>"}</button>
        </div>
      </div>
      {showAlert && (
        <AlertBox
          message="병원을 선택해주세요."
          onCancel={() => setShowAlert(false)} // AlertBox 닫기
        />
      )}
      {showDeleteBox && (
        <DeleteBox
          message={deleteTarget.message || "값을 0으로 설정하시겠습니까?"}
          pendingStatus={2}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
}

export default RevenueAndCostSetting;
