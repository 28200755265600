import React, { useState, useEffect } from "react";
import axios from "axios";
import AlertModal from "../../components/AlertModal";
import DateFilter from "./DateFilter";

function ExpenseHistoryList({ formData = {}, searchStatus, setSearchStatus }) {
  const [expenseHistory, setExpenseHistory] = useState([]);
  const [adSpendings, setAdSpendings] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingCodeId, setEditingCodeId] = useState(null);

  // 기본 날짜 범위 (7일 전 ~ 오늘)
  const today = new Date();
  today.setHours(today.getHours() + 9); // 9시간 추가

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(sevenDaysAgo.getHours() + 9); // 9시간 추가

  const [customDateRange, setCustomDateRange] = useState({
    startDate: sevenDaysAgo,
    endDate: today,
  });

  const [selectedDateOption, setSelectedDateOption] = useState("최근 7일");
  const [datePickerStatus, setDatePickerStatus] = useState(false);

  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchExpenseHistorySettings = async () => {
    try {
      const response = await axios.get("/api/adexpense/list-adexpense", {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search_title: formData?.search_title || "",
          search_hospital_name_id: formData?.search_hospital_name_id || "",
          start_date: customDateRange.startDate.toISOString().split("T")[0],
          end_date: customDateRange.endDate.toISOString().split("T")[0],
        },
      });

      if (response.data) {
        setExpenseHistory(response.data.expensesWithDetails);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      }
      setSearchStatus(false);
    } catch (error) {
      console.error("Failed to fetch url code settings:", error);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toISOString().split("T")[0];
    const timePart = date.toISOString().split("T")[1].split(".")[0];
    return `${datePart} ${timePart}`;
  };

  const handleAdspendChange = (id, value) => {
    const numericValue = value.replace(/,/g, "");
    if (!isNaN(numericValue)) {
      setAdSpendings({
        ...adSpendings,
        [id]: {
          ...adSpendings[id],
          ad_spend: numericValue,
        },
      });
      handleCodeChange(id, "ad_spend", numericValue);
    }
  };

  useEffect(() => {
    fetchExpenseHistorySettings();
  }, [formData.search_hospital_name_id, customDateRange, currentPage]);

  useEffect(() => {
    if (searchStatus) {
      fetchExpenseHistorySettings();
    }
  }, [searchStatus]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleCodeChange = (id, field, value) => {
    setExpenseHistory((prevCodes) =>
      prevCodes.map((code) =>
        code.id === id ? { ...code, [field]: value } : code
      )
    );
  };

  const handleEdit = (id) => {
    setEditingCodeId(id);
  };

  const handleEditSave = async (codeId) => {
    const codeToUpdate = expenseHistory.find((code) => code.id === codeId);

    const updateFields = {
      user_id: 1,
      id: codeId,
      url_code_setting_id: codeId,
      ad_spend: Number(codeToUpdate.ad_spend),
      spend_date: new Date(codeToUpdate.spend_date).toISOString(),
      updated_at: new Date().toISOString(),
    };

    try {
      const response = await axios.put(`/api/adexpense/update`, updateFields);

      if (response.status === 200) {
        alert("광고비가 성공적으로 업데이트되었습니다.");
        setExpenseHistory((prevCodes) =>
          prevCodes.map((code) =>
            code.id === codeId
              ? { ...code, updated_at: updateFields.updated_at }
              : code
          )
        );
        setEditingCodeId(null);
      } else {
        throw new Error("Failed to save");
      }
    } catch (error) {
      console.error("Failed to save ad spending:", error);
      alert("광고비 저장에 실패했습니다.");
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert("삭제할 항목을 선택하세요.");
      return;
    }

    try {
      const response = await axios.delete("/api/adexpense/delete", {
        data: { ids: selectedRows },
      });

      if (response.status === 200) {
        alert("선택한 항목이 삭제되었습니다.");
        setExpenseHistory((prevCodes) =>
          prevCodes.filter((code) => !selectedRows.includes(code.id))
        );
        setSelectedRows([]);
        toggleModal();
      } else {
        throw new Error("삭제 실패");
      }
    } catch (error) {
      console.error("Failed to delete selected rows:", error);
      alert("선택 삭제에 실패했습니다.");
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="expenseHistory">
      <div className="dateFilterdeleteExpense">
        <DateFilter
          customDateRange={customDateRange}
          setCustomDateRange={setCustomDateRange}
          selectedDateOption={selectedDateOption}
          setSelectedDateOption={setSelectedDateOption}
          datePickerStatus={datePickerStatus}
          setDatePickerStatus={setDatePickerStatus}
          onFilterChange={fetchExpenseHistorySettings}
          filters={{}}
        />

        <button className="deleteBtn" onClick={toggleModal}>
          선택삭제
        </button>
        {isModalOpen && (
          <AlertModal
            message="현재 선택한 코드를 정말 삭제하시겠습니까?"
            onConfirm={handleDelete}
            onCancel={toggleModal}
          />
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th style={{ width: "8%" }}>선택</th>
            <th>광고 제목</th>
            <th>매체</th>
            <th>광고비</th>
            <th>소진 일자</th>
            <th>수정 일자</th>
            <th>작성자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {expenseHistory &&
            expenseHistory.map((code) => (
              <tr key={code.id}>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(code.id)}
                    onChange={() => handleCheckboxChange(code.id)}
                  />
                </td>
                <td>{code.ad_title}</td>
                <td>{code.advertising_company_name}</td>
                <td>
                  <input
                    type="text"
                    name="ad_spend"
                    value={
                      editingCodeId === code.id
                        ? formatNumber(
                            adSpendings[code.id]?.ad_spend || code.ad_spend
                          )
                        : formatNumber(code.ad_spend)
                    }
                    disabled={editingCodeId !== code.id}
                    onChange={(e) =>
                      handleAdspendChange(code.id, e.target.value)
                    }
                    placeholder="광고비"
                  />
                </td>
                <td>
                  <input
                    type="date"
                    name="spend_date"
                    value={formatDate(code.spend_date)}
                    disabled={editingCodeId !== code.id}
                    onChange={(e) =>
                      handleCodeChange(code.id, "spend_date", e.target.value)
                    }
                  />
                </td>
                <td>
                  <span>{formatDateTime(code.updated_at)}</span>
                </td>
                <td>{code.user_name}</td>
                <td>
                  {editingCodeId === code.id ? (
                    <button
                      className="save-button"
                      onClick={() => handleEditSave(code.id)}
                    >
                      저장
                    </button>
                  ) : (
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(code.id)}
                    >
                      수정
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
}

export default ExpenseHistoryList;
