import React from "react";

function DeleteButton({ handleUpdateStatus }) {
  return (
    <div>
      <button
        onClick={() => handleUpdateStatus(0)}
        className="compo-delete-button"
      >
        {/* PUBLIC_URL을 사용하여 public 폴더 내의 이미지 파일 참조 */}
        <img
          src={process.env.PUBLIC_URL + "/images/trashcan.png"}
          alt="삭제"
          className="trashcan-icon"
        />
      </button>
    </div>
  );
}

export default DeleteButton;
