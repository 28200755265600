import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const MonthFilter = ({ selectedMonth, setSelectedMonth }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (month) => {
    setSelectedMonth(month.toString().padStart(2, "0"));
    setIsDropdownOpen(false);
  };

  // 월 배열 생성 (1월부터 12월)
  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <div className="month-filter">
      <div className="hospital-custom-select" onClick={handleDropdownClick}>
        {selectedMonth}월
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
      </div>

      {isDropdownOpen && (
        <ul className="custom-options">
          {months.map((month) => (
            <li
              key={month}
              onClick={() => handleOptionClick(month)}
              className={`option-item ${
                selectedMonth === month.toString().padStart(2, "0")
                  ? "selected"
                  : ""
              }`}
            >
              {month}월
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MonthFilter;
