// DateFilter.jsx
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "../customorDataPage/DateRangePicker";

function DateFilter({
  customDateRange,
  setCustomDateRange,
  selectedDateOption,
  setSelectedDateOption,
  datePickerStatus,
  setDatePickerStatus,
  onFilterChange,
  filters,
}) {
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  const dateOptions = [
    { label: "오늘", value: "today" },
    { label: "최근 7일", value: "last7days" },
    { label: "최근 30일", value: "last30days" },
    { label: "어제", value: "yesterday" },
    { label: "지난주 (오늘 제외)", value: "lastweek" },
  ];

  const formatKoreanDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Asia/Seoul",
    };
    return new Intl.DateTimeFormat("ko-KR", options).format(date);
  };

  const handleDateDropdownClick = () => {
    setIsDateDropdownOpen(!isDateDropdownOpen);
  };

  const handleDateOptionClick = (option, value) => {
    setSelectedDateOption(option);
    setIsDateDropdownOpen(false);
    updateDateRange(value);
  };

  const updateDateRange = (option) => {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (option) {
      case "today":
        startDate = endDate = new Date();
        break;
      case "yesterday":
        startDate = endDate = new Date(today.setDate(today.getDate() - 1));
        break;
      case "last7days":
        startDate = new Date(today.setDate(today.getDate() - 6));
        endDate = new Date();
        break;
      case "last30days":
        startDate = new Date(today.setDate(today.getDate() - 29));
        endDate = new Date();
        break;
      case "lastweek":
        const lastWeekEnd = new Date(today);
        lastWeekEnd.setDate(lastWeekEnd.getDate() - lastWeekEnd.getDay()); // 지난주 끝 (일요일)
        const lastWeekStart = new Date(lastWeekEnd);
        lastWeekStart.setDate(lastWeekEnd.getDate() - 6); // 지난주 시작 (월요일)

        startDate = lastWeekStart;
        endDate = lastWeekEnd;
        break;
      default:
        startDate = endDate = new Date();
        break;
    }

    setCustomDateRange({
      startDate: startDate,
      endDate: endDate,
    });

    onFilterChange({
      ...filters,
      // startDate: startDate.toISOString(),
      // endDate: endDate.toISOString(),
    });
  };

  const DatePickerHandler = () => {
    setDatePickerStatus(!datePickerStatus);
  };

  return (
    <div className="date-filter-container">
      <div className="date-filter-box">
        <div className="date-filter">
          <div className="custom-select" onClick={handleDateDropdownClick}>
            <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
            <span>{selectedDateOption}</span>
          </div>
          <span className="lineSeper">|</span>
          {isDateDropdownOpen && (
            <ul className="custom-options">
              {dateOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() =>
                    handleDateOptionClick(option.label, option.value)
                  }
                  className={`option-item ${
                    selectedDateOption === option.label ? "selected" : ""
                  }`}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* 날짜 표시 및 화살표 */}
        <div className="date-display" onClick={DatePickerHandler}>
          <span className="date-text">
            {formatKoreanDate(customDateRange.startDate)} ~
            {formatKoreanDate(customDateRange.endDate)}
          </span>
          <div className="divider large"></div>
          <div className="arrow-group">
            <FontAwesomeIcon icon={faChevronLeft} className="arrow-icon" />
            <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
          </div>
        </div>
      </div>
      <div className="dateRangePicker-box">
        {datePickerStatus && (
          <DateRangePicker
            customDateRange={customDateRange}
            setCustomDateRange={setCustomDateRange}
            closePicker={() => setDatePickerStatus(false)}
            setSelectedDateOption={setSelectedDateOption}
          />
        )}
      </div>
    </div>
  );
}

export default DateFilter;
