import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../customorDataPage/customerdata.scss";

const HospitalFilter = ({ selectedHospital, setSelectedHospital }) => {
  const [hospitalOptions, setHospitalOptions] = useState([]); // 초기화

  useEffect(() => {
    const fetchHospitalOptions = async () => {
      try {
        const response = await axios.get("/api/list/hospitals");
        if (response.data.items) {
          // 'items' 필드를 확인
          setHospitalOptions(response.data.items); // 'items'를 사용하여 상태 설정
        } else {
          console.error("병원 목록이 반환되지 않았습니다.");
        }
      } catch (error) {
        console.error("병원 목록을 불러오는 중 오류 발생:", error);
      }
    };

    fetchHospitalOptions();
  }, []);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (hospital) => {
    setSelectedHospital(hospital.id);
    setIsDropdownOpen(false);
  };

  const getHospitalName = () => {
    if (hospitalOptions.length > 0 && selectedHospital) {
      const hospital = hospitalOptions.find((h) => h.id === selectedHospital);
      return hospital ? hospital.name : "병원을 선택해주세요.";
    }
    return "병원을 선택해주세요.";
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // 상태 선언 위치를 useEffect 아래로 이동

  return (
    <div className="hospital-filter">
      <div className="hospital-custom-select" onClick={handleDropdownClick}>
        {getHospitalName()}
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
      </div>

      {isDropdownOpen && (
        <ul className="custom-options">
          <li
            key="all-hospitals"
            onClick={() =>
              handleOptionClick({ id: null, name: "병원을 선택해주세요." })
            }
            className={`option-item ${
              selectedHospital === null ? "selected" : ""
            }`}
          >
            병원을 선택해주세요.
          </li>
          {hospitalOptions.map((hospital) => (
            <li
              key={hospital.id}
              onClick={() => handleOptionClick(hospital)}
              className={`option-item ${
                selectedHospital === hospital.id ? "selected" : ""
              }`}
            >
              {hospital.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HospitalFilter;
